import gql from 'graphql-tag';

export const GET_CLIPS = gql`
  query {
    clips {
      id
      godoGoodsNo
    }
  }
`;

export const GET_SEARCH_KEYWORD_LIST = gql`
  query GET_SEARCH_KEYWORD_LIST($lang: String!) {
    searchKeywordList(lang: $lang) {
      keyword
    }
  }
`;

export const GET_CATEGORY_CHILD_LIST = gql`
  query ($lang: String!, $cateCds: [String!]!, $type: String) {
    getTransGodoCategoryChildItems(lang: $lang, cateCds: $cateCds, type: $type) {
      name
      code
      isLink
      isVisible
    }
  }
`;

export const GET_CATEGORY_LIST = gql`
  query ($lang: String!, $cateCds: [String!]!) {
    getTransGodoCategoryItems(lang: $lang, cateCds: $cateCds) {
      name
      code
    }
  }
`;

export const GET_GODO_BANNER_INFO = gql`
  query GET_GODO_BANNER_INFO($bannerCode: Float!) {
    getGodoBannerInfo(bannerCode: $bannerCode) {
      speed
      time
      effect
      width
      isPx
      height
      isSideButton
      sideButtonColor
      isNaviButton
      naviButtonType
      naviButtonColor
      naviButtonActiveColor
      naviButtonSize
      bannerList {
        imageUrl
        linkUrl
        color
        isBlank
      }
    }
  }
`;
export const GET_HEADER_INFO = gql`
  query ($lang: String!) {
    getBandBannerForPage(lang: $lang) {
      id
      text
      backgroundColor
      closeColor
      href
      isBlank
      startDate
      endDate
      closedShow
    }
  }
`;

export const GET_GODO_MODAL_INFO = gql`
  query GET_GODO_MODAL_INFO($url: String!) {
    getGodoPopup(url: $url) {
      isUseTodayHide
      todayHideAlign
      todayHideTextColor
      todayHideBgColor
      width
      height
      content
    }
  }
`;

export const ADD_CART_GOODS = gql`
  mutation ADD_CART_GOODS($goodsNo: String!) {
    productAddCart(goodsNo: $goodsNo)
  }
`;

export const ADD_WISH_GOODS = gql`
  mutation ADD_WISH_GOODS($goodsNo: Int!) {
    createClip(createClipInput: { godoGoodsNo: $goodsNo }) {
      id
    }
  }
`;

export const DELETE_WISH_GOODS = gql`
  mutation DELETE_WISH_GOODS($godoGoodsNo: Int!) {
    removeClip(removeClipInput: { godoGoodsNo: $godoGoodsNo })
  }
`;

export const GET_LANG_FAQ_TYPE_LIST = gql`
  query ($lang: String!) {
    faqAconTypes(lang: $lang) {
      id
      code
      i18n {
        name
        language {
          code
        }
      }
    }
  }
`;

export const GET_FAQ_LIST = gql`
  query ($lang: String) {
    faqAcons(lang: $lang) {
      id
      title
      subtitle
      body
      type {
        id
        code
      }
      language {
        id
        code
      }
      order
      created
      updated
    }
  }
`;

export const GET_PRODUCT_FAQ_LIST = gql`
  query ($lang: String, $typeId: Int) {
    faqAcons(lang: $lang, typeId: $typeId) {
      id
      title
      subtitle
      body
      type {
        id
        code
      }
      language {
        id
        code
      }
      order
      created
      updated
    }
  }
`;

export const GET_FAQ_ACON_PAGE = gql`
  query ($lang: String) {
    faqAconTypes(lang: $lang) {
      id
      code
      i18n {
        name
        language {
          id
          code
        }
      }
    }
    faqAcons(lang: $lang) {
      id
      title
      subtitle
      body
      type {
        id
        code
      }
      language {
        id
        code
      }
      order
      created
      updated
    }
  }
`;

export const GET_FAQ_CREATOR_CATEGORY = gql`
  query ($id: Int!) {
    faqCreatorCategories(id: $id) {
      id
      title
      subtitle
      body
      type {
        id
        code
        i18n {
          name
          lang
        }
      }
      lang
      order
      created
      updated
    }
  }
`;

export const GET_FAQ_CREATOR_PAGE = gql`
  query ($lang: String) {
    faqCreatorTypes(lang: $lang) {
      id
      code
      i18n {
        name
        lang
      }
    }
    faqCreators(lang: $lang) {
      id
      title
      subtitle
      body
      type {
        id
        code
      }
      lang
      order
      created
      updated
    }
  }
`;

export const GET_FAQ_ACON_ITEM = gql`
  query ($id: Int!) {
    faqAcon(id: $id) {
      id
      title
      subtitle
      body
      type {
        id
        code
      }
      language {
        id
        code
      }
      order
      created
      updated
    }
  }
`;
export const GET_CATEGORY_BANNER_INFO = gql`
  query ($godoCateCd: String!) {
    getCategory(godoCateCd: $godoCateCd) {
        type
        trans {
          lang
          visibleResultStats
          name
          banner {
            image
            anchor
            anchorTarget
            map
            color
          }
        }
    }
  }
`;
export const GET_PRODUCT_MAIN_IMAGE = gql`
  query ($godoGoodsNo: Int!, $lang: String!) {
    productDetailView(godoGoodsNo: $godoGoodsNo, lang: $lang) {
      relatedExtension
      relatedGodoGoodsNo
      extensionName
      title
      ogImageUrl
      mainImageUrl
    }
  }
`;

export const GET_REVIEW_LIST = gql`
    query ($goodsNo: String!, $lang: String!, $page: Int!, $limit: Int!, $rating: [Int!], $isExperimental: Boolean) {
        reviews(goodsNo: $goodsNo, lang: $lang, page: $page, limit: $limit, rating: $rating, isExperimental: $isExperimental) {
            id
            created
            rating
            isBestReview
            isNew
            isImage
            commentsCount
            author
            favoriteCount
            isFavorite
            godoMemNo
            imageUrl
            reviewImages {
                imageNumber
                imageUrl
            }
            reviewReplies {
                reviewReplyContents {
                    languageCode
                    content
                    isOriginal
                    reviewReplyId
                }
                reviewId
                created
                adminUser {
                    name
                    id
                    updated
                }
            }
            reviewContentsItems {
                title
                reviewId
                contents
                isOriginal
                languageCode
            }
        }
    }
`;
export const GET_REVIEW_LIST_SIMPLE = gql`
    query ($goodsNo: String!, $lang: String!, $page: Int!, $limit: Int!, $rating: [Int!], $isExperimental: Boolean) {
        reviews(goodsNo: $goodsNo, lang: $lang, page: $page, limit: $limit, rating: $rating, isExperimental: $isExperimental) {
            id
            created
            rating
            commentsCount
            author
            favoriteCount
            isFavorite
            isImage
            godoMemNo
            imageUrl
            reviewImages {
                imageUrl
            }
            reviewContentsItems(isOriginal: true)  {
                contents
            }
        }
    }
`;

export const GET_REVIEW_LIST_V2 = gql`
  query ($goodsNo: String!, $lang: String!, $page: Int!, $limit: Int!) {
    reviewsV2(goodsNo: $goodsNo, lang: $lang, page: $page, limit: $limit) {
      id
      rating
      title
      originalTitle
      author
      commentsCount
      isImage
      isNew
      isBestReview
      created
    }
  }
`;
export const GET_REVIEW_DETAIL_V2 = gql`
  query ($sno: Int!, $lang: String!) {
    reviewDetailV2(sno: $sno, lang: $lang) {
      contents
      originalContents
      originalLanguage
      memNo
      comments {
        contents
        originalContents
        originalLanguage
        isSecret
        reviewId
      }
    }
  }
`;
export const GET_REVIEW = gql`
  query Review($reviewId: Int!) {
    review(reviewId: $reviewId) {
      author
      commentsCount
      created
      favoriteCount
      id
      imageUrl
      isBestReview
      isFavorite
      isImage
      isNew
      godoGoodsNo
      godoOrderNo
      isSecret
      reviewContentsItems {
        contents
        isOriginal
        languageCode
        reviewId
        title
      }
      rating
      reviewImages {
        imageNumber
        imageUrl
      }
      reviewReplies {
        adminUser {
          name
        }
        created
        id
        reviewReplyContents {
          content
          isOriginal
          languageCode
        }
      }
    }
  }
`;
export const GET_MY_REVIEW = gql`
  query ($id: Int!) {
    getMyReview(id: $id) {
      id
      reviewContentsItems {
        title
        contents
        languageCode
        isOriginal
      }
      rating
      imageUrl
    }
  }
`;

export const GET_IS_UPDATE = gql`
  query ($goodsNo: String!, $lang: String!) {
    isUpdate(goodsNo: $goodsNo, lang: $lang)
  }
`;
export const GET_FLOATING = gql`
  query ($lang: String!, $godoGoodsNo: Int!) {
    productDetailFloatingCard(lang: $lang, godoGoodsNo: $godoGoodsNo) {
      title
      isSoldOut
      brand {
        id
        name
        godoBrandCd
        item(langCode: $lang) {
          name
        }
      }
      category {
        godoCateCd
        i18n {
          name
        }
      }
      view
      extension
      price
      realPrice
      benefitName
      benefitStartDate
      benefitEndDate
      modelConfigI18n {
        modelConfigId
        name
      }
      banner {
        id
        startDate
        endDate
        text
        href
        type {
          displayType
          code
        }
        image {
          fullName
        }
      }
    }
  }
`;

export const GET_FILE_INFO = gql`
  query ($goodsNo: String!, $lang: String!) {
    productFileInfo(goodsNo: $goodsNo, lang: $lang) {
      extensions
      dynamicConfig
      fileConfig {
        id
        name
        size
      }
      updateContents {
        id
        name
        date
      }
    }
  }
`;
export const GET_PRODUCT_COPYRIGHT = gql`
  query ($goodsNo: String!, $lang: String!) {
    productCopyright(goodsNo: $goodsNo, lang: $lang)
  }
`;
export const GET_PRODUCT_VIEW_DETAIL = gql`
  query ($goodsNo: Int!, $lang: String!) {
    productViewDetail(goodsNo: $goodsNo, lang: $lang) {
      contents
      banner {
        id
        image {
          fullName
        }
        href
      }
    }
  }
`;

export const GET_BANNERS_GOODS_NO = gql`
  query ($goodsNo: Int!, $lang: String!) {
    getBannersByGodoNo(godoGoodsNo: $goodsNo, lang: $lang) {
      id
      type {
        code
      }
      image {
        fullName
      }
      href
    }
  }
`;

export const GET_PRODUCT_REVIEW_WRITE_INFO = gql`
  query ($goodsNo: String!, $lang: String!, $sno: Int) {
    productReviewWriteInfo(goodsNo: $goodsNo, lang: $lang, sno: $sno) {
      reviewTitle
      reviewContents
      reviewRating
      title
      brandName
      imageUrl
    }
  }
`;

export const GET_INPROGRESS_COUPONS = gql`
  query ($lang: String!) {
    getDisplayItems(lang: $lang) {
      lang
      title
      conditionFirst
      conditionSecond
      imageUrl
      couponNo
      startDate
      endDate
    }
  }
`;
export const GET_COUPONS_DATA = gql`
  query ($couponNos: [String!]) {
    getCouponItems(couponNos: $couponNos) {
      godoCouponNo
      remaining
    }
  }
`;

export const GET_EVENT_BANNER_ITEMS = gql`
  query ($isInprogressEvent: Boolean!, $lang: String!) {
    getEventBannerItems(isInprogressEvent: $isInprogressEvent, lang: $lang) {
      id
      language {
        code
        name
      }
      bannerTextFirst
      bannerTextSecond
      bannerTextThird
      settingLink
      image {
        fullName
      }
    }
  }
`;
export const GET_POINTS = gql`
  query ($lang: String!, $page: Int!, $startDate: String!, $endDate: String!) {
    getPointList(lang: $lang, page: $page, startDate: $startDate, endDate: $endDate) {
      totalCount
      items {
        point
        afterPoint
        contents
        regDate
        deleteScheduleDate
      }
    }
  }
`;
export const ADD_CART_GOODS_ITEMS = gql`
  mutation ($goodsNos: [String!]!) {
    productItemsAddCart(goodsNos: $goodsNos)
  }
`;
export const DELETE_SCRAP_ITEMS = gql`
  mutation ($godoGoodsNos: [Int!]!) {
    removeClips(removeClipsInput: { godoGoodsNos: $godoGoodsNos })
  }
`;

// XXX: 2021 크리스마스 이벤트용 임시
export const GET_USER_COUPON_ISSUE_COUNT = gql`
  query ($couponNo: Int) {
    GetCouponIssueCount(couponNo: $couponNo)
  }
`;

export const GET_RECENT_ORDERS_V2 = gql`
  query ($langCode: String!) {
    recentOrdersV2 {
      orderNo
      orderedAt
      orderedProducts {
        sno
        title
        godoGoodsNo
        price
        status
        statusString
        hasWrittenReview
        reviewSno
        reviewId
        product(langCode: $langCode) {
          title
          price
          salePrice
          fileExtensionList {
            name
            extensionConfig {
              name
            }
          }
          brand {
            godoBrandCd
            item(langCode: $langCode) {
              name
            }
          }
          imageList {
            type
            uploadFileId
            item {
              fullName
            }
          }
        }
      }
    }
  }
`;
export const GET_CHECKED_PASSWORD_CHANGE = gql`
  query ($key: String!) {
    checkedPasswordChange(key: $key)
  }
`;

export const CHECK_AUTHENTICATION = gql`
  query ($email: String!) {
    checkAuthentication(email: $email)
  }
`;
export const GET_INVITATION_LANG_CODE = gql`
  query GET_INVITATION_LANG_CODE($code: String!) {
    invitationLangCode(code: $code)
  }
`;

export const GET_ORDER_COUPON_LIST = gql`
  query ($lang: String!, $godoCartSnos: [String!]!) {
    getOrderCouponItems(lang: $lang, godoCartSnos: $godoCartSnos) {
      memberCouponNo
      couponNo
      name
      couponKindType
      couponBenefit
      couponBenefitType
      couponMaxBenefit
      isCouponMaxBenefit
      couponApplyProductType
      couponApplyGoods {
        goodsNm
        goodsNo
      }
      couponApplyCategory {
        no
        name
      }
      couponMinOrderPrice
      couponMinOrderType
      isCouponApplyDuplicate
      startDate
      endDate
      regDt
      isProductCoupon
      discountPrice
      products {
        godoGoodsNo
        discountPrice
      }
      isAvailable
    }
  }
`;

export const GET_CATEGORY_FILTER = gql`
  query ($lang: String!, $brandCode: String, $type: String) {
    syncCategory(lang: $lang, brandCd: $brandCode, type: $type) {
      name
      code
      isLink
      isVisible
      type
    }
  }
`;

export const GET_EVENT_FRIEND_INVITATION_PAGE_INFO = gql`
  query GET_EVENT_FRIEND_INVITATION_PAGE_INFO($langCode: String!) {
    friendsCount
    getTotalInvitationPoint
    godoUserInvitationCode(langCode: $langCode)
  }
`;

export const GET_GODO_USER_NAME = gql`
  query GET_GODO_USER_NAME {
    godoUserName
  }
`;

export const GET_CHECKED_BUSINESS = gql`
  query {
    checkedBusiness
  }
`;

export const GET_NOTIFICATION = gql`
  query {
    aconNotifications {
      id
      memNo
      type
      message
    }
  }
`;
export const GET_MAIN_PRODUCT_LIST = gql`
  query ($lang: String!, $sno: Int!) {
    mainProductList(lang: $lang, sno: $sno) {
      sno
      title
      source
      productList {
        id
        goodsNo
        brandCd
        cateCd
        title
        badgeName
        brandName
        price
        salePrice
        extension {
          name
        }
        isEndSales
        isPromotion
        promotionEndDate
        mainImageUrl
        subImageUrl
        isAconOnly
        orderCount
        viewCount
        reviewCount
        godoCreated
      }
    }
  }
`;

export const GET_CATEGORY_TYPE = gql`
  query ($godoCateCd: String!) {
    getCategory(godoCateCd: $godoCateCd) {
        type
        trans {
            lang
            visibleResultStats
            name
            banner {
                image
                anchor
                anchorTarget
                map
                color
            }
        }
    }
  }
`;
